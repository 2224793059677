import * as React from 'react'
import Layout from '../components/layout'

class CleophasPage extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");    
        script.async = true;    
        script.src = "/gallery.js";             
        document.body.appendChild(script);
        console.log("child appended");
    }
    render() {
        return (
        <Layout pageTitle="Cleophas">
            <section className="featured-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center">
                                <h3 className="text-uppercase">Het Huis Van Cleophas</h3>
                                <span className="text-uppercase">waar gastronomie, gezelligheid en sfeer elkaar ontmoeten..</span>
                            </div>
                        </div>
                    </div>
                    <p>Wenst u een meeting in Pand99 te combineren met een lunch of diner? Dan bent u bij onze partner Het Huis Van Cleophas aan het juiste adres! Verras uw genodigden met deze unieke locatie! Het Huis Van Cleophas stelt hun ruime parking gratis ter beschikking en maakt zo de combinatie werken/dineren zeer gemakkelijk! Voor alle info of reservaties <a href="mailto:info@cleophas.be">info@cleophas.be</a> met vermelding van uw boeking bij Pand99.</p>

                    <div className="galleria" style={{height: "40rem"}}>
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas1.jpg" />
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas2.jpg" />
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas3.jpg" />
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas4.jpg" />
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas5.jpg" />
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas6.jpg" />
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas7.jpg" />
                                <img alt="Afbeelding van Cleophas" src="/images/cleophas/cleophas8.jpg" />
                    </div>
                </div>
            </section>
        </Layout>
        )
    }
}

export default CleophasPage